import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button // Import the Button component
} from '@mui/material';

const MyCard = ({ data }) => {

  const handleClick = async () => {
      console.log('GET Response:', data);    
  };

  const handleDelete = async () => {
    try {
      // Send a DELETE request to the server with the card's ID
      //const response = await axios.delete(`http://localhost:3001/card/${data.id}`, config);
      //console.log('Delete Response:', response.data);
      // Add any additional logic after a successful deletion, such as refreshing the list
    } catch (error) {
      console.error('Error deleting card:', error);
    }
  };

  return (
    <Box>
      <Card sx={{margin: 'auto', '&:hover': {cursor: 'pointer'}}} onClick={handleClick}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {data.title}
          </Typography>
          <Typography variant="body2">
            {data.date}
          </Typography>
          <Typography variant="body1" paragraph>
            {data.description}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {data.tags}
          </Typography>
          {/* Delete button */}
          <Button
            onClick={(e) => {
              e.stopPropagation(); // Prevent the click from triggering the card's onClick event
              handleDelete();
            }}
            sx={{ marginTop: '10px' }} // Add some space above the delete button
          >
            Delete
          </Button>
        </CardContent>
      </Card>

    </Box>
  );
};

export default MyCard;
