// src/components/SearchBar.js

import React, { useState } from 'react';
import { Paper, InputBase, IconButton, Chip, Box } from '@mui/material';

const SearchBar = ({ onSearch }) => {
  const [tags, setTags] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const newTag = inputValue.trim();
      if (newTag && !tags.includes(newTag)) {
        setTags([...tags, newTag]);
        setInputValue('');
        onSearch([...tags, newTag]); // Update search results with new tag list
      }
    }
  };

  const handleDelete = (tagToDelete) => () => {
    const newTags = tags.filter(tag => tag !== tagToDelete);
    setTags(newTags);
    onSearch(newTags); // Update search results after tag is removed
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  return (
    <Paper
      component="form"
      sx={{
        p: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 'auto',
        margin: '0 auto 20px',
      }}
      onSubmit={(e) => e.preventDefault()} // Prevent the default form submit action
    >
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, p: 0.5 }}>
        {tags.map((tag, index) => (
          <Chip
            key={index}
            label={tag}
            onDelete={handleDelete(tag)}
          />
        ))}
      </Box>
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="enter tag(s)"
        inputProps={{ 'aria-label': 'search tag(s)' }}
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
      />
      <IconButton type="submit" sx={{ p: '10px' }} aria-label="search" onClick={() => onSearch(tags)}>
      </IconButton>
    </Paper>
  );
};

export default SearchBar;