import { Box } from "@mui/system";
import React from "react";
import CardGrid from './CardGrid';
import CreateCardButton from './CreateCardButton';

function Dashboard(props) {
  return (
    <Box>
        <Box display="flex" justifyContent="center" alignItems="center" p={3}>
            <CardGrid data={props.data}/>
        </Box>
        <CreateCardButton />
    </Box>
  );
}
export default Dashboard;