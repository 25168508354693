import React, { useState } from 'react';
import { TextField, Chip, Box, Stack } from '@mui/material';

const TagsInput = (props) => {
    const { tags, setTags } = props;
    const [inputValue, setInputValue] = useState('');

  const handleKeyDown = (event) => {
    if (['Enter', ','].includes(event.key)) {
      event.preventDefault();
      let value = inputValue.trim();
      // Ensure the user input is not empty and the tag is not already added
      if (value && !tags.includes(value)) {
        setTags([...tags, value]);
        setInputValue(''); // Clear the input field
      }
    }
  };

  const handleDelete = (chipToDelete) => () => {
    setTags((chips) => chips.filter((chip) => chip !== chipToDelete));
    console.log('Current tags (after delete):', tags); // Debug log
  };

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  return (
    <Box sx={{ width: '100%', mb: 3 }}>
      <TextField
        fullWidth
        variant="standard"
        size="small"
        value={inputValue}
        placeholder="Enter Tags (press enter)"
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        sx={{ margin: "1rem 0" }}
        InputProps={{
          startAdornment: (
            <Stack direction="row" spacing={1}>
              {tags.map((tag, index) => (
                <Chip
                  key={index}
                  label={tag}
                  onDelete={handleDelete(tag)}
                />
              ))}
            </Stack>
          ),
        }}
      />
    </Box>
  );
};

export default TagsInput;