import React, {useState, useEffect} from 'react';
import './App.css';
import Header from './components/HeaderBar';
import Footer from './components/FooterBar';
import Dashboard from './components/Dashboard';
import Search from './components/Search';

function App() {
  // States
  const [cards, setCards] = useState([]);
  const [filteredCards, setFilteredCards] = useState([]);

  useEffect(() => {
    const getCard = async () => {
        const resp = await fetch(`/api/card`);
        const cardResp = await resp.json();
        setCards(cardResp);
        setFilteredCards(cardResp);
    };

    getCard();
}, []);

if (!Object.keys(cards).length) return <div />;

  // Search function
  const handleSearch = (searchTags) => {
    if (searchTags.length === 0) {
      setFilteredCards(cards); // No tags, show all cards
    } else {
      const lowercasedTags = searchTags.map(tag => tag.toLowerCase());
      const filtered = cards.filter(card =>
        Array.isArray(card.tags) && // Check if tags is an array
        card.tags.some(tag => lowercasedTags.includes(tag.toLowerCase()))
      );
      
      setFilteredCards(filtered);
    }
  };

  // Render
  return (
    <div className="App">
      <Header/>
      <Search onSearch={handleSearch}/> {/* Update the prop name here */}
      <Dashboard data={filteredCards}/> {/* Use filteredCards here */}
      <Footer className="footer"/>
    </div>
  );
}

export default App;