// src/components/Footer.js

import React from 'react';
import { Box, Typography } from '@mui/material';

const Footer = () => {
  return (
    <Box component="footer" sx={{ textAlign: 'center', py: 2, mt: 4, borderTop: '1px solid rgba(0, 0, 0, 0.12)' }}>
        {/* Shaded horizontal line */}
        <Typography variant="caption" display="block" gutterBottom>
            © 2021 Surya Polina
        </Typography>
    </Box>
  );
};

export default Footer;
