// src/CardGrid.js

import React from 'react';
import Masonry from '@mui/lab/Masonry';
import Card from './Card/index.js'; // Ensure this is the correct path to your MyCard component
import './static/styles.css';

const CardGrid = (props) => { // Change 'data' to 'props'

  return (
      <Masonry className="masonry-container" columns={{ xs: 1, sm: 2, md: 3, lg: 4 }} spacing={2}>
        {props.data.map((data, index) => ( // Change 'data.map' to 'props.data.map'
          <Card key={index} data={data}></Card> // Ensure you are using 'MyCard' and not 'Card' if that's the name of your card component
        ))}
      </Masonry>
  );
};

export default CardGrid;
