import React from 'react';
import ReactQuill from 'react-quill';
import 'quill/dist/quill.snow.css';

const RichTextEditor = ({ html, setHtml }) => {
    const handleChange = (content) => {
        setHtml(content);
    };

  return (
    <div>
      <ReactQuill
        theme="snow"
        value={html}
        onChange={handleChange}
      />
    </div>
  );
};

export default RichTextEditor;