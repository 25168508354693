// src/components/CreateCardButton.js

import React, { useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import TagsInput from './TagInput';
import RichTextEditor from './RichTextEditor';

const CreateCardButton = () => {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    title: '',
    date: '',
    html: '',
    tags: [],
    image: null
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async () => {
    console.log('Sending formData to server:', formData); // Debug log
    try {
      // create post request to add card to database
      //const response = await axios.post('http://localhost:3001/card/secretroute', formData, config);
      // Log the response from the server (optional)
      //console.log('Server Response:', response.data);
      
      // Close the modal or perform other actions as needed
      //handleClose();
    } catch (error) {
      // Handle any errors here
      console.error('Error:', error);
    }
  };

  const handleTitleChange = (e) => {
    console.log(e.target.name, e.target.value); // Add this to debug
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleEditorChange = (htmlContent) => {
    setFormData({ ...formData, html: htmlContent });
  };  

  const handleTagsChange = (newTags) => {
    if (!Array.isArray(newTags)) {
      console.error('newTags is not an array:', newTags);
      return;
    }
    console.log(newTags);
    setFormData({ ...formData, tags: newTags });
  };

  const handleImageChange = (e) => {
    setFormData({ ...formData, image: e.target.files[0] }); // Update the state with the selected file
  };

  return (
    <Box textAlign="center" my={2}>
        <Button
          variant="contained"
          onClick={handleOpen}
          sx={{
            padding: '6px 16px',
            textTransform: 'none',
            fontSize: '0.875rem',
            justifyContent: 'start',
            color: 'rgba(0, 0, 0, 0.87)', // Text color, adjust as needed
            backgroundColor: 'white', // Button color set to white
            borderColor: 'rgba(0, 0, 0, 0.23)', // Adjust border color if needed
            ':hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)', // Adjust hover color if needed
              // Reset the border color on hover if needed
            },
          }}
        >
          Create Card
        </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Create Card</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="title"
            label="Title"
            type="text"
            fullWidth
            variant="standard"
            onChange={handleTitleChange}
          />
          <TextField
            margin="dense"
            name="date"
            label="Date"
            type="date"
            fullWidth
            variant="standard"
            InputLabelProps={{ shrink: true }}
            onChange={handleTitleChange}
          />
          <RichTextEditor
            html={formData.html}
            setHtml={handleEditorChange}
          />
          <TagsInput
            tags={formData.tags}
            setTags={handleTagsChange}
          />
          <TextField
            type="file"
            name="image"
            fullWidth
            variant="standard"
            onChange={handleImageChange}
            inputProps={{
              accept: 'image/*' // Only accept image types
            }}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CreateCardButton;