// personalblog/src/Header/Header.js

import React from 'react';
import './static/style.css';
import SocialLinksHeader from './SocialMedia';

function Header() {
    return (
        <div className="header">
            <div className="headerTitles">
                <span className="headerTitleLg">Surya Polina</span>
                <span className="headerTitleSm">Software Engineer</span>
            </div>
            <div className="headerSocialLinks">
                <SocialLinksHeader />
            </div>
        </div>
    )
}
export default Header;
