// src/components/SocialLinksHeader.js

import React from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';

const SocialLinksHeader = () => {
  return (
    <Box display="flex" justifyContent="flex-end" alignItems="center" padding={2}>
      <Tooltip title="LinkedIn">
        <IconButton component="a" href="https://www.linkedin.com/in/suryapolina" target="_blank" rel="noopener noreferrer">
        </IconButton>
      </Tooltip>
      <Tooltip title="Twitter">
        <IconButton component="a" href="https://twitter.com/suryapolina" target="_blank" rel="noopener noreferrer">
        </IconButton>
      </Tooltip>
      <Tooltip title="Email">
        <IconButton component="a" href="mailto:suryapolina@gmail.com">
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default SocialLinksHeader;
